import volet from 'assets/services/fenetres/volet.jpeg'

export const Help = () => (
    <div className='max-w-7xl mx-auto py-16 px-4'>
        <div className='flex flex-col md:flex-row justify-between'>
            <div>
                <h3 className="max-w-xl text-2xl">Vous souhaitez mettre en place une fenêtre avec volet roulant pour plus de facilité d’utilisation ?</h3>
                <p className='text-lg mt-4'>Notre équipe est prête à s'en occuper, analyser les prix, contactez-nous pour faire un devis.</p>
            </div>
            <img className='mix-blend-multiply md:max-w-[50%]' src={volet} alt='volet' />
        </div>
    </div>
)