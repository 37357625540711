import { ReactNode } from 'react'

import { Navbar } from './components/navbar/navbar'

interface MainLayoutProps {
    children: ReactNode;
}

export const MainLayout = (props: MainLayoutProps) => {
    const { children } = props

    return (
        <main>
            <Navbar />
            {children}
        </main>
    )
}