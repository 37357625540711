import { Calculator } from './components/calculator'

interface CalculationProps {
    gray?: boolean;
    shouldNavigate?: boolean;
}

export const Calculation = (props: CalculationProps) => {
    const { gray, shouldNavigate } = props
    return (
        <div id='calculation' className={`${gray ? 'bg-[#f7f7f7]' : 'bg-white'} py-16`}>
            <div className='max-w-7xl mx-auto px-4'>
                <div className='flex items-center md:items-baseline text-center md:text-start flex-col md:flex-row md:justify-between'>
                    <div className='absolute dots w-40 h-40 -translate-x-10 -translate-y-12' />
                    <div className='relative'>
                        <h2 className='text-3xl md:text-4xl max-w-3xl'>Faire une estimation de prix pour l'isolation intérieure ou extérieure.</h2>
                        <p className='text-xl md:text-2xl text-gray-400'>Nous offrons un prix abordable.</p>
                        <p className='text-xl mt-4'>Extérieure: 1 m² = 195 €</p>
                        <p className='text-xl mb-4 md:mb-0'>Intérieure: 1 m² = 95 €</p>
                    </div>
                    <Calculator shouldNavigate={shouldNavigate} />
                </div>
            </div>
        </div>
    )
}

