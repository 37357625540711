import isolation from 'assets/services/isolation.webp'
import fenetres from 'assets/services/window.webp'
import travaux from 'assets/services/travaux.jpeg'
import { useNavigate } from 'react-router-dom'

export const Services = () => {
    const navigate = useNavigate()

    const handleClick = (url: string) => {
        navigate(url)
        window.scrollTo({ top: 0 })
    }

    return (
        <div id='services' className='bg-[#f7f7f7] py-16'>
            <div className='max-w-7xl mx-auto px-4'>
                <div className='flex flex-col-reverse gap-8'>
                    <div className='flex flex-col items-center justify-center md:flex-row gap-8'>
                        <div className='relative w-[384px] h-[384px] cursor-pointer hover:shadow-2xl' onClick={() => handleClick('/isolation')}>
                            <img className='w-[384px] h-[384px] object-cover' src={isolation} alt='isolation' />
                            <div className='bg-black opacity-70 w-full h-full absolute top-0 z-10' />
                            <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white z-20 text-4xl'>
                                ISOLATION
                            </div>
                        </div>
                        <div className='relative w-[384px] h-[384px] cursor-pointer hover:shadow-2xl' onClick={() => handleClick('/fenetres')}>
                            <img className='w-[384px] h-[384px] object-cover' src={fenetres} alt='isolation' />
                            <div className='bg-black opacity-70 w-full h-full absolute top-0 z-10' />
                            <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white z-20 text-4xl'>
                                FENÊTRES
                            </div>
                        </div>
                        <div className='relative w-[384px] h-[384px] cursor-pointer hover:shadow-2xl' onClick={() => handleClick('/travaux')}>
                            <img className='w-[384px] h-[384px] object-cover' src={travaux} alt='isolation' />
                            <div className='bg-black opacity-70 w-full h-full absolute top-0 z-10' />
                            <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white z-20 text-4xl text-center'>
                                TRAVAUX D'INTÉRIEUR
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='absolute dots w-40 h-40 -translate-y-6' />
                        <div className='relative'>
                            <h2 className='text-4xl z-20'>Les services que nous proposons:</h2>
                            <h3 className='text-lg mt-4'> - Isolation intérieure et extérieure de la maison.</h3>
                            <h3 className='text-lg mt-2'> - Fourniture et pose de fenêtre et volet roulant.</h3>
                            <h3 className='text-lg mt-2'> - Tous types de travaux d'intérieur.</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}