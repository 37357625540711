import interieur from 'assets/services/isolation/interieur.jpg';
import exterieur from 'assets/services/isolation/exterieur.jpg';

export const Types = () => {

    return (
        <>
            <div className='max-w-7xl mx-auto py-16 px-4'>
                <div className='flex flex-col md:flex-row gap-8'>
                    <img className='object-cover w-[512px] h-[256px]' src={interieur} alt="interieur" />
                    <div>
                        <h2 className='text-4xl mb-2'>Pourquoi isoler l'intérieur ?</h2>
                        <p>Simple et économique, l'isolation des murs par l'intérieur préserve votre logement des pertes de chaleur. Mais ce n'est pas tout ! Outre les économies de chauffage, c'est votre confort thermique qui s'améliore considérablement tandis que votre patrimoine immobilier prend de la valeur.</p>
                        <span>L'isolation des murs par l'intérieur peut vous permettre de faire des économies d'énergie allant jusqu'à <span className='font-bold'>15% sur votre facture de chauffage</span>.</span>
                    </div>
                </div>
            </div>
            <div className='bg-[#f7f7f7]'>
                <div className='max-w-7xl mx-auto py-16 px-4'>
                    <div className='flex flex-col md:flex-row gap-8'>
                        <div>
                            <h2 className='text-4xl mb-2'>Pourquoi isoler l'extérieur ?</h2>
                            <p>Avec une isolation par l'extérieur, vos murs sont plus hermétiques, et donc protégés des agressions du froid ou du soleil. De plus, les ponts thermiques responsables des déperditions de chaleur et de la désagréable sensation de « paroi froide » de vos murs, sont quasiment tous supprimés.</p>
                            <span>L'isolation par l'extérieur peut vous permettre de faire des économies d'énergie allant jusqu'à <span className='font-bold'>25% sur votre facture de chauffage</span>.</span>

                        </div>
                        <img className='object-cover w-[512px] h-[256px]' src={exterieur} alt="interieur" />
                    </div>
                </div>
            </div>
        </>
    )
}