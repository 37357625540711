import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { Home } from 'pages/home/home'
import { MainLayout } from 'layout/MainLayout'
import { Isolation } from 'pages/isolation/isolation'
import { Fenetres } from 'pages/fenetres/fenetres'
import { Travaux } from 'pages/travaux/travaux'

export default function Routing() {

    return (
        <BrowserRouter>
            <MainLayout>
                <Routes>
                    <Route path='*' element={<Home />} />
                    <Route path='/isolation' element={<Isolation />} />
                    <Route path='/fenetres' element={<Fenetres />} />
                    <Route path='/travaux' element={<Travaux />} />
                </Routes>
            </MainLayout>
        </BrowserRouter>
    )
}
