import { useState } from 'react';
import { Button, Radio, TextInput } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

interface CalculatorProps {
    shouldNavigate?: boolean;
}

export const Calculator = (props: CalculatorProps) => {
    const { shouldNavigate } = props

    const navigate = useNavigate()

    const [done, setDone] = useState(false)
    const [type, setType] = useState<'interieur' | 'exterieur'>('interieur')
    const [m2, setM2] = useState<string>()

    const handleSubmit = () => {
        if (m2 && m2 !== '0') {
            setDone(true)
        }
    }

    const handleContact = async () => {
        if (shouldNavigate) {
            await navigate('/', { state: { scroll: 'contact' } })
        }
        window.scrollTo({ top: (document.getElementById('contact')?.getBoundingClientRect().top || 0) + window.pageYOffset - 100, behavior: 'smooth' })
    }

    return (
        <div className={`max-w-xs w-full ${shouldNavigate ? 'bg-[#f7f7f7]' : 'bg-white'} shadow-md p-6 rounded-md text-start`}>
            <h2 className='text-2xl'>Devis d'isolation</h2>
            <h4 className='text-gray-500 mb-2'>Estimation gratuite en une minute.</h4>
            {done ? (
                <>
                    <div className='flex flex-col gap-2'>
                        <span>Type d'isolation: <span className='font-semibold'>{type}</span></span>
                        <span>Prix approximatif: <span className='font-semibold'>{parseInt(m2 || '0') * (type === 'interieur' ? 95 : 195)} €</span></span>
                    </div>
                    <div className='flex justify-end'>
                        <Button className='mt-4 bg-[#023878] hover:bg-[#023878]' onClick={handleContact}>
                            NOUS CONTACTER
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    <Radio.Group value={type} onChange={(type: 'interieur' | 'exterieur') => setType(type)} label="Type d'isolation" className='mb-2'>
                        <div className='flex gap-4'>
                            <Radio value="interieur" label="Par l'intérieur" />
                            <Radio value="exterieur" label="Par l'extérieur" />
                        </div>
                    </Radio.Group>
                    <TextInput value={m2} onChange={(event) => setM2(event.currentTarget.value)} type='number' label='Surface à isoler (m²)' placeholder='(m²)' />
                    <div className='flex justify-end'>
                        <Button className='mt-4 bg-[#023878] hover:bg-[#023878]' onClick={handleSubmit}>
                            Estimation
                        </Button>
                    </div>
                </>
            )}
        </div>
    )
}