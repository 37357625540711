import { Button, Textarea, TextInput } from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form';
import { FcCheckmark } from 'react-icons/fc'
import { GrFacebook } from 'react-icons/gr'
import { BsTelephoneFill } from 'react-icons/bs'
import { MdEmail } from 'react-icons/md'
import { useState } from 'react';

import * as yup from 'yup'
import { sendMail } from 'api/sendMail';

interface ContactProps {
    gray?: boolean;
}

export const Contact = (props: ContactProps) => {
    const { gray } = props

    const [mailSent, setMailSent] = useState(false)

    const schema = yup.object().shape({
        name: yup.string().min(2, 'Nom incorrect!'),
        phone: yup.string().matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/, 'Numéro invalide!'),
        code: yup.string().min(1, 'Code postal invalide!'),
        email: yup.string().matches(/^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, 'Email invalide!'),
        message: yup.string().min(1, 'Message invalide!')
    });

    const form = useForm({
        validate: yupResolver(schema),
        initialValues: {
            name: '',
            phone: '',
            code: '',
            email: '',
            message: ''
        }
    })

    const handleSubmit = (values: any) => {
        sendMail(values)
        form.reset()
        setMailSent(true)
    }

    return (
        <>
            {mailSent ? (
                <div id='contact' className='w-full flex items-center justify-center mt-16'>
                    <div className='text-[#1E2D3B] p-20 flex flex-col items-center justify-center'>
                        <FcCheckmark size={128} />
                        <h2 className='text-2xl'>La demande a été envoyée!</h2>
                        <p className='lg max-w-lg text-center'>Votre demande sera prise en charge par la société Postica Rénovation vous allez avoir un retour d’ici 24h.</p>
                    </div>
                </div>
            ) : (
                <div id='contact' className={`${gray && 'bg-[#F7F7F7]'} py-16 px-4`}>
                    <div className='max-w-7xl mx-auto'>
                        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                            <div className='absolute dots w-40 h-40 -translate-x-12 -translate-y-6' />
                            <div className='flex flex-col md:flex-row gap-4 relative'>
                                <div className='text-center md:text-start'>
                                    <h3 className='text-4xl'>Contactez-nous pour recevoir plus de détails.</h3>
                                    <p className='text-xl md:text-2xl text-gray-400'>Nos experts sont prêts à vous aider à estimer les travaux.</p>
                                    <a href='https://www.facebook.com/Postica94' target='_blank' rel="noreferrer" className='flex items-center gap-4 mt-8'>
                                        <GrFacebook color='#38569E' size={24} />
                                        <p>Suivez-nous sur Facebook</p>
                                    </a>
                                    <a href='tel:+33752525680' className='flex items-center gap-4 mt-4'>
                                        <BsTelephoneFill size={24} />
                                        <p>+33 7 52 52 56 80</p>
                                    </a>
                                    <a href='mailto:posticarenovationmenuiserie@gmail.com' className='flex items-center gap-4 mt-4'>
                                        <MdEmail size={24} />
                                        <p>posticarenovationmenuiserie@gmail.com</p>
                                    </a>
                                </div>
                                <div className='w-full max-w-md flex flex-col gap-4 bg-white p-4'>
                                    <TextInput {...form.getInputProps('name')} label='Nom' placeholder='Jean' required />
                                    <TextInput {...form.getInputProps('phone')} label='Téléphone' placeholder='+33...' required type='number' />
                                    <TextInput {...form.getInputProps('code')} label='Code postal' placeholder='91600' required type='number' />
                                    <TextInput {...form.getInputProps('email')} label='Email' placeholder='jean@paris.fr' required type='email' />
                                    <Textarea {...form.getInputProps('message')} label='Votre message' placeholder="Bonjour, j'ai une question..." required />
                                    <div className='flex justify-end'>
                                        <div>
                                            <Button type='submit' className='bg-[#023878] hover:bg-[#023878]] w-full'>Contactez-nous</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    )
}