export const FenetresMain = () => {

    return (
        <div className='fenetres-bg'>
            <div className='bg-black bg-opacity-70 h-full'>
                <div className='flex flex-col items-center justify-center h-full text-white'>
                    <h1 className='text-6xl lg:text-7xl text-center'>FENÊTRES ET VOLET ROULANT</h1>
                </div>
            </div>
        </div>
    )
}