import axios from "axios";

import { useEffect, useState } from "react";

import { Footer } from "layout/components/footer/footer";
import { Calculation } from "pages/home/containers/calculation/calculation";
import { APIResponse } from "pages/home/home";
import { IsolationMain } from "./containers/main";
import { Types } from "./containers/types";
import { importAll } from "utils/utils";
import { Skeleton } from "@mantine/core";

export const Isolation = () => {
  const [apiResponse, setApiResponse] = useState<APIResponse>({
    reviews: [],
    rating: 0,
    user_ratings_total: 0,
  });

  const images = importAll(
    require.context(
      "assets/last",
      false,
      /\.(png|jpe?g|svg|webp)$/
    )
  );

  const [loading, setLoading] = useState(
    Object.keys(images).reduce((acc: any, curr: any) => {
      acc[curr] = true;
      return acc;
    }, {})
  );

  const handleImageLoad = (key: string) => {
    setLoading((prev: any) => ({ ...prev, [key]: false }));
  };

  useEffect(() => {
    document.title =
      "Isoler les murs de votre maison par l'intérieur ou l'extérieur";

    axios
      .get("https://posticarenovation.fr:5000/reviews")
      .then((resp) => setApiResponse(resp?.data))
      .catch(() =>
        setApiResponse({ reviews: [], rating: 0, user_ratings_total: 0 })
      );
  }, []);

  return (
    <>
      <IsolationMain />
      <Types />
      <Calculation shouldNavigate />
      <div className="bg-[#f7f7f7] py-16">
        <div className="max-w-7xl mx-auto px-4">
          <div>
            <div className="absolute dots w-40 h-40 -translate-x-12 -translate-y-6" />
            <h3 className="text-4xl relative">Notre dernière réalisation.</h3>
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 justify-center pt-8">
            {Object.keys(images).map((key: string, i: number) => (
              <div key={i}>
                {loading[key] && <Skeleton w={296} h={296} />}
                <img
                  className="w-full h-full object-cover aspect-square"
                  src={images[key]}
                  alt={key}
                  loading="lazy"
                  onLoad={() => handleImageLoad(key)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer
        rating={apiResponse.rating}
        user_ratings_total={apiResponse.user_ratings_total}
      />
    </>
  );
};
