import { useEffect, useState } from 'react'
import { Advantages } from './containers/advantages/advantages'
import { Benefits } from './containers/benefits/benefits'
import { Calculation } from './containers/calculation/calculation'
import { Contact } from './containers/contact/contact'
import { Main } from './containers/main/main'
import { Reviews } from './containers/reviews/reviews'
import { Services } from './containers/services/Services'
import { Footer } from 'layout/components/footer/footer'

import qualibat from 'assets/qualibat.webp'

import axios from 'axios'

export interface APIResponse {
    reviews: ReviewProps[];
    rating: number;
    user_ratings_total: number;
}

export interface ReviewProps {
    author_name: string;
    profile_photo_url: string;
    rating: number;
    text: string;
}

export const Home = () => {
    const [apiResponse, setApiResponse] = useState<APIResponse>({ reviews: [], rating: 0, user_ratings_total: 0 })

    useEffect(() => {
        document.title = 'Postica Rénovation - Entreprise de rénovation et construction'

        axios.get(
            'https://posticarenovation.fr:5000/reviews',
        )
            .then(resp => setApiResponse(resp?.data))
            .catch(() => setApiResponse({ reviews: [], rating: 0, user_ratings_total: 0 }))
    }, [])

    return (
        <>
            <Main />
            <div className='max-w-7xl mx-auto'>
                <Advantages />
            </div>
            <Services />
            <Benefits />
            <Calculation gray />
            <div className='max-w-7xl mx-auto py-16 px-4'>
                <div className='flex flex-col-reverse md:flex-row items-center text-center md:text-start'>
                    <div>
                        <h1 className='text-4xl z-20'>Nous sommes certifiés Qualibat</h1>
                        <h3 className='mt-2 text-lg'>Grâce à notre certification Qualibat RGE, vous pouvez avoir confiance en notre savoir-faire et en notre capacité à réaliser des travaux de haute qualité, dans le respect des normes environnementales les plus exigeantes.</h3>
                    </div>
                    <img className='max-w-[250px] mt-4 md:mt-0' src={qualibat} alt='qualibat' />
                </div>
            </div>
            <Contact gray />
            <Reviews reviews={apiResponse.reviews} />
            <Footer rating={apiResponse.rating} user_ratings_total={apiResponse.user_ratings_total} />
        </>
    )
}