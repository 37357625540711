import combles from 'assets/services/travaux/combles.jpeg'
import loft from 'assets/services/travaux/loft.jpeg'
import decoration from 'assets/services/travaux/decoration.jpeg'
import escalier from 'assets/services/travaux/escalier.jpeg'
import chauf from 'assets/services/travaux/chauf.jpeg'
import elec from 'assets/services/travaux/elec.jpeg'
import cheminee from 'assets/services/travaux/cheminee.jpeg'
import mesure from 'assets/services/travaux/mesure.webp'
import appart from 'assets/services/travaux/appart.jpeg'
import chambre from 'assets/services/travaux/chambre.webp'
import cuisine from 'assets/services/travaux/cuisine.jpeg'
import fenetre from 'assets/services/travaux/chambre.webp'
import maison from 'assets/services/travaux/maison.webp'
import plafond from 'assets/services/travaux/plafond.jpeg'
import residence from 'assets/services/travaux/residence.jpeg'
import bain from 'assets/services/travaux/bain.jpeg'
import sol from 'assets/services/travaux/sol.jpeg'
import manger from 'assets/services/travaux/manger.jpeg'

export const TravauxTypes = () => {

    return (
        <div className='max-w-7xl mx-auto py-16 px-4'>
            <div>
                <div className='absolute dots w-40 h-40 -translate-x-12 -translate-y-6' />
                <h3 className='text-4xl relative'>Nous sommes prêts à réaliser tous types de travaux intérieurs.</h3>
                <p className='text-xl md:text-2xl text-gray-400 relative'>Nos experts sont prêts à vous aider à estimer les travaux.</p>
            </div>
            <div className='flex flex-wrap gap-8 justify-center mt-8 relative'>
                <TravauxType img={combles} label='Aménagement de combles' />
                <TravauxType img={loft} label='Création de loft' />
                <TravauxType img={decoration} label="Décoration d'intérieur" />
                <TravauxType img={escalier} label='Escalier sur mesure ou classique' />
                <TravauxType img={chauf} label='Installation de chauffage' />
                <TravauxType img={elec} label='Installation électrique' />
                <TravauxType img={cheminee} label='Installer une cheminée' />
                <TravauxType img={mesure} label='Placard et dressing sur mesure' />
                <TravauxType img={appart} label="Rénovation d'appartement" />
                <TravauxType img={chambre} label='Rénovation de chambre' />
                <TravauxType img={cuisine} label='Rénovation de cuisine' />
                <TravauxType img={fenetre} label='Rénovation de fenêtre et porte' />
                <TravauxType img={maison} label='Rénovation de maison' />
                <TravauxType img={plafond} label='Rénovation de mur et plafond' />
                <TravauxType img={residence} label='Rénovation de résidence secondaire' />
                <TravauxType img={bain} label='Rénovation de salle de bain' />
                <TravauxType img={sol} label='Revêtement de sol' />
                <TravauxType img={manger} label='Salon et salle à manger' />
            </div>
        </div>
    )
}

const TravauxType = (props: { img: string, label: string }) => {
    const { img, label } = props

    return (
        <div>
            <img className='w-[384px] h-[384px] object-cover' src={img} alt={label} />
            <h3 className='text-lg'>{label}</h3>
        </div>
    )
}