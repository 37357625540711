import axios from "axios"
import { Footer } from "layout/components/footer/footer"
import { Contact } from "pages/home/containers/contact/contact"
import { APIResponse } from "pages/home/home"
import { useEffect, useState } from "react"
import { TravauxMain } from "./containers/main"
import { TravauxTypes } from "./containers/types"

export const Travaux = () => {

    const [apiResponse, setApiResponse] = useState<APIResponse>({ reviews: [], rating: 0, user_ratings_total: 0 })

    useEffect(() => {
        document.title = "Rénovation d'intérieur"
        axios.get(
            'https://posticarenovation.fr:5000/reviews',
        )
            .then(resp => setApiResponse(resp?.data))
            .catch(() => setApiResponse({ reviews: [], rating: 0, user_ratings_total: 0 }))
    }, [])

    return (
        <>
            <TravauxMain />
            <TravauxTypes />
            <Contact gray />
            <Footer rating={apiResponse.rating} user_ratings_total={apiResponse.user_ratings_total} />
        </>
    )
}