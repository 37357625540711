import axios from "axios"
import { Footer } from "layout/components/footer/footer"
import { Contact } from "pages/home/containers/contact/contact"
import { APIResponse } from "pages/home/home"
import { useEffect, useState } from "react"
import { Help } from "./containers/help"
import { FenetresMain } from "./containers/main"
import { PrixTable } from "./containers/table"

export const Fenetres = () => {
    const [apiResponse, setApiResponse] = useState<APIResponse>({ reviews: [], rating: 0, user_ratings_total: 0 })

    useEffect(() => {
        document.title = 'Fenétres avec volet roulant'
        axios.get(
            'https://posticarenovation.fr:5000/reviews',
        )
            .then(resp => setApiResponse(resp?.data))
            .catch(() => setApiResponse({ reviews: [], rating: 0, user_ratings_total: 0 }))
    }, [])

    return (
        <>
            <FenetresMain />
            <Help />
            <PrixTable />
            <Contact />
            <Footer rating={apiResponse.rating} user_ratings_total={apiResponse.user_ratings_total} />
        </>
    )
}