import { Table } from "@mantine/core"

const volets = [
    { name: 'PVC', price: '300 à 500 €', size: '-' },
    { name: 'Bois', price: '350 à 100 €', size: '-' },
    { name: 'Alu', price: '600 à 1000 €', size: '-' },
    { name: 'Fenêtre de toit', price: '500 à 700 €', size: '-' },
    { name: 'Porte fenêtre', price: '800 à 1 400 €', size: '-' },
    { name: 'PVC 2 vantaux', price: '450 à 500 €', size: '75 x 100 cm' },
    { name: 'PVC 2 vantaux', price: '550 €', size: '115 x 120 cm' },
    { name: 'PVC 2 vantaux', price: '600 à 650 €', size: '135 x 140 cm' },
    { name: 'PVC 2 vantaux', price: '650 à 700 €', size: '175 x 100 cm' },
    { name: 'PVC 2 vantaux', price: '700 à 750 €', size: '215 x 140 cm' },
    { name: 'PVC oscillo battante', price: '500 à 550 €', size: '75 x 100 cm' },
    { name: 'PVC oscillo battante', price: '600 à 750 €', size: '95 x 120 cm' },
    { name: 'Baie vitrée alu', price: '750 à 800 €', size: '200 x 240 cm' }
]

export const PrixTable = () => {
    const rows = volets.map((item) => (
        <tr key={item.name}>
            <td>{item.name}</td>
            <td>{item.size}</td>
            <td>{item.price}</td>
        </tr>
    ));

    return (
        <div className='bg-[#f7f7f7]'>
            <div className='max-w-7xl mx-auto py-16 px-4'>
                <h3 className="text-4xl">Nos prix, les plus disponibles !</h3>
                <p className='text-gray-400 mb-4'>Voici quelques tarifs qu’il vous faut garder à l’esprit pour s’équiper d’une fenêtre avec volet roulant :</p>
                <Table striped highlightOnHover>
                    <thead>
                        <tr>
                            <th>Nature de la fenêtre</th>
                            <th>Taille</th>
                            <th>Prix</th>
                        </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </Table>
                <p className="mt-4 text-lg">Le prix de l'installation est entre 150 et 500 €. Pour le raccord du volet roulant au réseau d’électricité, il faudra ajouter 100 € à ce budget. De la même façon, si une ouverture doit être créée pour installer cette fenêtre, il faudra compter un coût supplémentaire de 1 000 € si la fenêtre prend place dans un mur porteur.</p>
            </div>
        </div>
    )
}