import { GrFacebook } from 'react-icons/gr'
import { BsTelephoneFill } from 'react-icons/bs'
import { MdEmail } from 'react-icons/md'
import { AiFillStar } from 'react-icons/ai'
import { useLocation, useNavigate } from 'react-router-dom'

export const Footer = (props: { rating: number, user_ratings_total: number }) => {
    const { rating, user_ratings_total } = props
    const navigate = useNavigate()
    const location = useLocation()

    const handleLogoClick = () => {
        if (['/isolation', '/fenetres', '/travaux'].includes(location.pathname)) {
            navigate('/')
        }
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    const handleLinks = async (url: string) => {
        if (['/isolation', '/fenetres', '/travaux'].includes(url)) {
            await navigate(url)
            window.scrollTo({ top: 0 })
        } else {
            await navigate('/')
            window.scrollTo({ top: (document.getElementById(url)?.getBoundingClientRect().top || 0) + window.pageYOffset - 100, behavior: 'smooth' })
        }
    }

    return (
        <div className='bg-gray-800'>
            <div className='max-w-7xl mx-auto pt-16 pb-8 px-4 text-white'>
                <div className='flex justify-between flex-col md:flex-row'>
                    <div>
                        <div className='flex flex-col leading-none font-extrabold text-4xl cursor-pointer' onClick={handleLogoClick}>
                            <p>POSTICA</p>
                            <p>RÉNOVATION</p>
                        </div>
                        <div className='mt-4 md:mt-8'>
                            <div className='flex'>{Array.from(Array(rating).keys()).map(x => <AiFillStar key={x} color='orange' size={24} />)}</div>
                            <a href='https://g.page/r/CRB-D1XaybCTEBM/review' target='_blank' rel='noreferrer' className='underline'>{user_ratings_total} Avis</a>
                        </div>
                    </div>
                    <div>
                        <p className='font-bold text-lg mb-4 hidden md:block'>LIENS</p>
                        <div className='flex flex-wrap gap-4 md:block py-4 md:py-0'>
                            <p className='cursor-pointer hover:underline' onClick={() => handleLinks('home')}>Accueil</p>
                            <p className='cursor-pointer hover:underline' onClick={() => handleLinks('aboutus')}>À propos</p>
                            <p className='cursor-pointer hover:underline' onClick={() => handleLinks('/isolation')}>Isolation</p>
                            <p className='cursor-pointer hover:underline' onClick={() => handleLinks('/fenetres')}>Fenêtres</p>
                            <p className='cursor-pointer hover:underline' onClick={() => handleLinks('/travaux')}>Travaux d'intérieur</p>
                            <p className='cursor-pointer hover:underline' onClick={() => handleLinks('calculation')}>Estimation</p>
                        </div>
                    </div>
                    <div>
                        <p className='font-bold text-lg mb-4 hidden md:block'>CONTACT</p>
                        <a href='https://www.facebook.com/Postica94' target='_blank' rel="noreferrer" className='flex items-center gap-4'>
                            <GrFacebook color='#fff' size={24} />
                            <p>Suivez-nous sur Facebook</p>
                        </a>
                        <a href='tel:+33752525680' className='flex items-center gap-4 mt-4'>
                            <BsTelephoneFill size={24} />
                            <p>+33 7 52 52 56 80</p>
                        </a>
                        <a href='mailto:posticarenovationmenuiserie@gmail.com' className='flex items-center gap-4 mt-4'>
                            <MdEmail size={24} />
                            <p>posticarenovationmenuiserie@gmail.com</p>
                        </a>
                    </div>
                </div>
                <div className='h-[1px] w-full bg-white my-8' />
                <div className='flex items-center flex-col text-center'>
                    <p>© 2023 POSTICA RÉNOVATION. Tous droits réservés.</p>
                    <span>Réalisé par <a href='mailto:sergiu.loghin2003@gmail.com'>Sergiu Loghin</a></span>
                </div>
            </div>
        </div>
    )
}