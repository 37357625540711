import dpe from 'assets/dpe.webp'

export const Benefits = () => {

    return (
        <div className='max-w-7xl mx-auto py-16 px-4'>
            <div className='flex flex-col-reverse md:flex-row items-center text-center md:text-start'>
                <img className='max-w-[250px] mt-4 md:mt-0' src={dpe} alt='dpe' />
                <div>
                    <h1 className='text-4xl z-20'>Quels sont les avantages de l'isolation ?</h1>
                    <h3 className='mt-2 text-lg'>L'isolation fait partie des travaux incontournables pour réduire vos dépenses énergétiques, améliorer votre confort ou faire augmenter la valeur de votre maison. L'isolation d'une maison, en particulier du toit et des murs extérieurs, peut empêcher plus de 50 % des pertes de chaleur.</h3>
                </div>
            </div>
        </div>
    )
}